var $forms = [];

const screenWidth = {
    mobile: '576',
    tabletP: '768',
    tabletL: '992',
    desktop: '1200',
    desktopXL: '1367',
    maxScreenSize: '1920',
    unit: 'px'
};

function initFunctions() {

    showMoreText();
    desktopLinksDisabled();
}
function resizeFunctions() {
    desktopLinksDisabled();
}

$(document).ready(function () {


    $('.js-tab-component .tab-component__button').on('click', handleTabClickEvent);

    initFunctions();
    initPlyrVideos();
    initCustomSelect();
    initScrollToTopAnchor();

    $('video').on('fullscreenchange webkitfullscreenchange mozfullscreenchange', function() {
        if($(this).hasClass('fullscreen')){
            $(this).removeClass('fullscreen');
        } else {
            $(this).addClass('fullscreen');
        }
    });
});
$(window).resize(resizeFunctions);



function initCustomSelect() {

    // https://select2.github.io/options.html
    $('select:not(.simple)').each(function () {
        var select = $(this);

        // initialize only once
        if (select.hasClass('initialized')) return;

        var classes = select.attr("class");
        select.addClass('initialized');

        if (select.attr("readonly") === "readonly") {
            classes += " customSelect2Readonly";
        }

        var $select2 = select.select2({
            minimumResultsForSearch: 2,
            dropdownAutoWidth: true
        }).on('select2:open', function (evt) {
            $(this).blur().hide();
        }).on('select2:close', function (evt) {
            $(this).blur().show();
        }).on('select2:select', function (evt) {
            $(this).focus().show();
            $(this).trigger('change');
        });

        $select2.data('select2').$container.addClass(classes);
    });
}

function initScrollToTopAnchor() {
    $('.footer__button').click(function () {
        $('html,body').animate({
            scrollTop: 0
        }, 'slow');
    });
}

function handleTabClickEvent(event) {
    if ($(window).width() > 576 || $(event.currentTarget).closest(".enable-mobile")) {

        var $target = $(event.currentTarget);
        var $oldTarget = $target.closest(".tab-component").find(".tab-component__button--active");
        var $targets = $target.closest(".tab-component").find(".tab-component__button");

        if ($target.hasClass("tab-component__button--active")) return;

        $targets.removeClass("easy-out-button");

        $oldTarget.addClass("easy-out-button")
            .removeClass("easy-in-button-left")
            .removeClass("easy-in-button-right");

        $target.closest(".tab-component")
            .find(".tab-component__button")
            .removeClass("tab-component__button--active");

        $target.closest(".reference-tab-component")
            .find(".tab-content")
            .removeClass("tab-content--active");

        if ($oldTarget.index() < $target.index()) {
            $target.addClass("easy-in-button-left");
        } else {
            $target.addClass("easy-in-button-right");
        }

        $target.addClass("tab-component__button--active");

        $target.closest(".reference-tab-component")
            .find(".tab-content:eq(" + $target.index() + ")")
            .addClass("tab-content--active");
    }
}

/**
 * This function initializes Plyr library for handling embedded videos.
 * Embedded videos within .js-slider containers are initialized without autoplay.
 * */
function initPlyrVideos() {
    initPlyrOnEmbeddedVideos();
    initPlyrOnHtml5Videos();
}

function initPlyrOnEmbeddedVideos() {
    const sliderVideosSelector = '.js-slider .js-player > div,' // hero banner videos
        + '.js-bannerSlider .js-player > div,' // banner slider videos
        + '.js-historySliders .js-player > div,' // history videos
        + '.js-visualBoxGallerySlider .js-player > div'; // visual box videos

    const $otherPlayers = $(sliderVideosSelector);

    //Browser check, Internet explorer(6-7-8-9-10-11) not support play and pause on slick.js
    if (isIEbrowser()) {
        initPlyrOnEmbeddedVideo($otherPlayers, false);
    } else {
        initPlyrOnEmbeddedVideo($otherPlayers, false);
    }

    const $autoplayPlayers = $('.js-player > div').not($otherPlayers);
    initPlyrOnEmbeddedVideo($autoplayPlayers, true, "&autoplay=1&playsinline=1");

    

}

$(document).on('click', '.js-player .plyr__video-wrapper', function(event) {
    event.stopPropagation();
    var videoElement = $(this).find('video')[0]; // Trova l'elemento video nel DOM
    if (videoElement) {
        // Il video è nel DOM
        
        if (videoElement.paused) {
            videoElement.play(); // Avvia la riproduzione del video
        } else {
            videoElement.pause(); // Metti in pausa la riproduzione del video
        }
    } else {
        // Il video è in un iframe 
        $(this).siblings('.plyr__controls').find('.plyr__control[data-plyr="play"]')[0].blur();
        $(this).siblings('.plyr__controls').find('.plyr__control[data-plyr="play"]')[0].click().blur();

    }
});



function initPlyrOnEmbeddedVideo($players, autoStart, iframeSrcParams) {
    Array.from($players).map(p => {
        const $player = $(p);

        if (iframeSrcParams) {
            const $iframe = $player.find('> iframe');
            const currentSrc = $iframe.attr('src');
            $iframe.attr('src', currentSrc + iframeSrcParams);
        }

        const settingControls = ['play','progress','current-time','mute','volume','fullscreen'];
        const isHideControls = $player.data('hide-controls');
        const autoplay = $player.data('autoplay') || false;

        const instance = new Plyr(p, {
            muted: true,
            autoplay: autoplay,
            clickToPlay: false,
            loop: { active: true },
            controls: isHideControls ? [] : settingControls,
            //iconUrl: '/webfiles/latest/images/icons/plyr-icons-custom.svg'
        });

        $player.data('plyr', instance);
        startPlyrWhenReady($player, autoplay);

        instance.on('enterfullscreen', (event) => {
            
            $(event.target).find('video').addClass('fullscreen');
        });
        instance.on('exitfullscreen', (event) => {
            
            $(event.target).find('video').removeClass('fullscreen');
        });
    });
}

function initPlyrOnHtml5Videos() {
    const sliderHtml5VideosSelector = '.js-slider .js-player > video,' // hero banner videos
        + '.js-bannerSlider .js-player > video,' // banner slider videos
        + '.js-historySliders .js-player > video,' // history videos
        + '.js-visualBoxGallerySlider .js-player > video'; // visual box videos

    const $sliderHtml5Players = $(sliderHtml5VideosSelector);
    initPlyrOnEmbeddedVideo($sliderHtml5Players, false);

    const $inlineHtml5Players = $('.js-player > video').not($sliderHtml5Players);
    initPlyrOnEmbeddedVideo($inlineHtml5Players, true);
}

/**
 * This function gets called by the various components that instantiate a slider containing embedded videos.
 * */
function handlePlyrInsideSlider($slider) {
 
    //Browser check, Internet explorer(6-7-8-9-10-11) not support play and pause on slick.js
    if (isIEbrowser()) {

        let $firstSlide = $($slider.get(0).slick.$slides.get(0)).find('.js-player > div');
        const $firstSlideHtml5Video = $firstSlide.find('video');
        if ($firstSlideHtml5Video.length > 0) {
            $firstSlide = $firstSlideHtml5Video;
        }
        startPlyrWhenReady($firstSlide, true);

    } else {

        $slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            const $currentVideoContainer = $(slick.$slides.get(currentSlide)).find('.js-player > div');
            if ($currentVideoContainer.length > 0) {

                var currentVideoPlyr;

                const $html5Video = $currentVideoContainer.find('video');
                if ($html5Video.length > 0) {
                    currentVideoPlyr = $html5Video.data('plyr');
                } else {
                    currentVideoPlyr = $currentVideoContainer.data('plyr');
                }

                currentVideoPlyr.pause();
            }

            const $nextVideoContainer = $(slick.$slides.get(nextSlide)).find('.js-player > div');
            if ($nextVideoContainer.length > 0) {
                var nextVideoPlyr;

                const $html5Video = $nextVideoContainer.find('video');
                if ($html5Video.length > 0) {
                    nextVideoPlyr = $html5Video.data('plyr');
                } else {
                    nextVideoPlyr = $nextVideoContainer.data('plyr');
                }

                nextVideoPlyr.play();
            }
        });

        let $firstSlide = $($slider.get(0).slick.$slides.get(0)).find('.js-player > div');
        const $firstSlideHtml5Video = $firstSlide.find('video');
        if ($firstSlideHtml5Video.length > 0) {
            $firstSlide = $firstSlideHtml5Video;
        }
        startPlyrWhenReady($firstSlide, true);
    }
}

function startPlyrWhenReady($plyr, autoStart) {

    if ($plyr.length > 0) {

        const player = $plyr.data('plyr');
        if (player.ready) {
            handlePlyrAutostart(player, autoStart);

        } else {

            player.on('ready', event => {
                handlePlyrAutostart(event.detail.plyr, autoStart);
            });
        }
    }
}

function handlePlyrAutostart(plyr, autoStart) {
    if (autoStart) {
        plyr.play();

    } else {
        plyr.stop();
    }
}

function playStopOnCover($player){
    $(document).on('click', '.plyr__poster', function(){
        if($(this).parent().parent('.plyr--paused').length){
            
            $player.play();
          
        } else {
            
            $player.stop();
          
        }
    });
   
}

function showMoreText(){

    const $el = $('.tpl_textFocus .textBlock');
    const $p = $('.tpl_textFocus .textBlock .text p');
    const $target = $('.btn__showMore__label');
    const $targetShow = $('.btn__showMore__label--show');
    const $targetHide = $('.btn__showMore__label--hide');

    let i=0;
    $p.each( function(e){
        if($(this).text().trim()!=""){i++};
    })

    if(i >= 2 ){
        $el.addClass('collapsed');
    }

    $targetShow.click(function() {
        $target.removeClass('show');
        $targetHide.addClass('show');
        $el.removeClass('collapsed');
        $el.addClass('expanded');
    });

    $targetHide.click(function() {
        $target.removeClass('show');
        $targetShow.addClass('show');
        $el.removeClass('expanded');
        $el.addClass('collapsed');
    });

}


function desktopLinksDisabled(){

    const $target = $('a.link__disabled--desktop');

    if( isMobile() === false ){

        $target.each(function(){
            let url = $(this).attr('href');
            $(this).removeAttr("href");
            $(this).attr('data-href', url );
        })
    }
    else{
        $target.each(function(){
            let url = $(this).attr('data-href');
            $(this).removeAttr("data-href");
            $(this).attr('href', url );
        })
    }
}
